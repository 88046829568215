import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<>
			<footer>
				<p>
					<Link className="footer-gdpr" to="/privacy-policy" target="_blank">
						GDPR
					</Link>
					{new Date().getFullYear()} © Web vytvoril{" "}
					<a href="https://adamkula.sk/" target="_blank" rel="noreferrer">
						Adam Kula
					</a>
					.
				</p>
			</footer>
		</>
	);
};

export default Footer;
