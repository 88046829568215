import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sk from 'date-fns/locale/sk';
import subDays from "date-fns/subDays";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Alert, Stack } from "@mui/material";
import emailjs from "emailjs-com";
import axios from "axios";
import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from "date-fns";
import { Helmet } from 'react-helmet';


registerLocale('sk', sk)

const messageRequiredEmail = "Email je povinný.";
const messageRequiredPhone = "Číslo je povinné.";
const messageRequiredPlace = "Miesto podujatia je povinné.";
const messageRequiredDate = "Dátum je povinný.";
const messageRequiredEvent = "Vyberte si druh podujatia";
const messageRequiredProduct = "Vyberte si produkt.";
const messageRequiredCustom = "Vyberte si efekty na vaše podujatie";
const messageRequiredMessage = "Zanechajte nám informácie o vašom podujatí.";
const messageRequiredGDPR = "Súhlas je potrebný pre odoslanie emailu.";

const SuccessfullySubmitted = "Formulár sa podarilo úspešne odoslať.";
const ErrorSubmitted = "Niečo sa pri odosielaní pokazilo.";


const Kontakt = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isErrorSubmitted, setIsErrorSubmitted] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    formState,
    watch,
  } = useForm();

	// Test PHP
  // const onSubmit = async (values) => {
  //   let config = {
  //     method: "post",
  //     url: "/api/index.php",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: values,
  //   };

  //   console.log("submited data = ", values["datum"]);

  //   try {
  //     const response = await axios(config);
  //     if (response.status === 200) {
  //       reset();
  //       setIsSuccessfullySubmitted(true);
  //     }
  //   } catch (err) {
  //     setIsErrorSubmitted(true);
  //   }
  // };

	const formatDateAndTimeForEmail = (date) => {
  	return format(date, "dd.MM.yyyy 'o' HH:mm");
		// return format(date, "dd.MM.yyyy");
	};

	 const sendEmail = (formData) => {
		// Transformujte hodnotu z DatePickeru pred odoslaním emailovej správy
  	const formattedDate = formatDateAndTimeForEmail(formData.datum);
    emailjs
			.send("service_owt1gzq", "template_9kwm83b", { ...formData, datum: formattedDate }, "8Bo7LwyYK3rwQwcu5")
      .then(
        (result) => {
          console.log(result.text);
					setIsSuccessfullySubmitted(true);
        },
        (error) => {
          console.log(error.text);
					setIsErrorSubmitted(true);
        }
      );
    reset();
  };


  const location = useLocation();
  const customEffect = watch("balik");
	const date = watch("datum");

	// console.log(date)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div>
				<Helmet>
					<link rel="canonical" href="https://www.specialneefekty.sk/kontakt" />
				</Helmet>
        <main className="container contact-page">
          <section>
            <div className="border"></div>
            <h4>Kontaktuje nás</h4>
            <div className="wrap-formular wrap-content">
              <h6>Vyplňte formulár nižšie a čoskoro sa vám ozveme.</h6>

              <div>
                <form onSubmit={handleSubmit(sendEmail)}>
                  <section className="input-field">
                    <div className="form-control">
                      <label className="label-input">E-mail</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Váš E-mail"
                        {...register("email", {
                          required: true,
                          maxLength: 30,
                          pattern: /^\S+@\S+$/i,
                        })}
                      />

                      {errors.email && errors.email.type === "required" && (
                        <Alert severity="error" className="error-form-message">
                          {messageRequiredEmail}
                        </Alert>
                      )}
                      {errors.email && errors.email.type === "maxLength" && (
                        <Alert severity="error" className="error-form-message">
                          Maximálna dĺžka prekročená.
                        </Alert>
                      )}
                    </div>

                    <div className="form-control">
                      <label className="label-input">Telefónne číslo</label>
                      <input
                        type="tel"
                        id="phone"
                        placeholder="Vaše telefónne číslo"
                        {...register("phone", {
                          required: true,
                          maxLength: 20,
                        })}
                      />

                      {errors.phone && errors.phone.type === "required" && (
                        <Alert severity="error" className="error-form-message">
                          {messageRequiredPhone}
                        </Alert>
                      )}
                      {errors.phone && errors.phone.type === "maxLength" && (
                        <Alert severity="error" className="error-form-message">
                          Maximálna dĺžka prekročená.
                        </Alert>
                      )}
                    </div>

                    <div className="form-control">
                      <label className="label-input">Miesto podujatia</label>
                      <input
                        type="text"
                        id="miesto"
                        placeholder="Kde sa podujatie nachádza"
                        {...register("miesto", {
                          required: true,
                          maxLength: 100,
                        })}
                      />

                      {errors.miesto && errors.miesto.type === "required" && (
                        <Alert severity="error" className="error-form-message">
                          {messageRequiredPlace}
                        </Alert>
                      )}
                      {errors.miesto && errors.miesto.type === "maxLength" && (
                        <Alert severity="error" className="error-form-message">
                          Maximálna dĺžka prekročená.
                        </Alert>
                      )}
                    </div>

                    <div className="form-control">
                      <label className="label-input">Termín podujatia</label>
											{/* ReactDataPicker */}
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="datum"
                        id="datum"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <ReactDatePicker
                            placeholderText="Kedy sa podujatie koná"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            minDate={subDays(new Date(), 0)}
														dateFormat="dd/MM/yyyy"
														showTimeSelect
														locale="sk"
                          />
                        )}
                      />

											{/* MUI data picker -funkčný ale css som neupravil*/}
											{/* <Controller
												name="datum"
												id="datum"
												control={control}
												rules={{ required: true }}
												render={({ field: { onChange, onBlur, value } }) => (
													<LocalizationProvider dateAdapter={AdapterDateFns}>
														<DatePicker
															// label="Kedy sa podujatie koná"
															defaultValue={new Date()}
															value={value}
															onChange={(newValue) => onChange(newValue)}
															onBlur={onBlur}
															minDate={new Date()}
															openPickerOnClick
															renderInput={(params) => <TextField {...params} />}
														/>
													</LocalizationProvider>
												)}
											/> */}

                      {errors.datum && errors.datum.type === "required" && (
                        <Alert severity="error" className="error-form-message">
                          {messageRequiredDate}
                        </Alert>
                      )}
                    </div>
                  </section>

                  <section className="input-radio">
                    <div className="wrap-box-left">
                      <div className="type-event box-radio">
                        <label>Druh podujatia</label>
                        <div className="wrap-radio">
                          <div className="form-control">
                            <input
                              {...register("podujatie", { required: true })}
                              type="radio"
                              value="Svadba"
                              id="svadba"
                            />
                            <label className="label-type" htmlFor="svadba">
                              Svadba
                            </label>
                          </div>
                          <div className="form-control">
                            <input
                              {...register("podujatie", { required: true })}
                              type="radio"
                              value="Event"
                              id="event"
                            />
                            <label className="label-type" htmlFor="event">
                              Event
                            </label>
                          </div>
                          <div className="form-control">
                            <input
                              {...register("podujatie", { required: true })}
                              type="radio"
                              value="Stužková"
                              id="stuzkova"
                            />
                            <label className="label-type" htmlFor="stuzkova">
                              Stužková
                            </label>
                          </div>
                          <div className="form-control">
                            <input
                              {...register("podujatie", { required: true })}
                              type="radio"
                              value="Ples"
                              id="ples"
                            />
                            <label className="label-type" htmlFor="ples">
                              Ples
                            </label>
                          </div>

                          <div className="form-control">
                            <input
                              {...register("podujatie", { required: true })}
                              type="radio"
                              value="Iné"
                              id="ine"
                            />
                            <label className="label-type" htmlFor="ine">
                              Iné
                            </label>
                          </div>
                        </div>

                        {errors.podujatie &&
                          errors.podujatie.type === "required" && (
                            <Alert
                              severity="error"
                              className="error-form-message"
                            >
                              {messageRequiredEvent}
                            </Alert>
                          )}
                      </div>

                      <div className="product-packages box-radio">
                        <label>Navrhované balíky</label>
                        <div className="wrap-radio">
                          <div className="form-control">
                            <input
                              {...register("balik", { required: true })}
                              type="radio"
                              value="Romantický balík"
                              id="romance"
                            />
                            <label className="label-type" htmlFor="romance">
                              Romantický
                            </label>
                          </div>
                          <div className="form-control">
                            <input
                              {...register("balik", { required: true })}
                              type="radio"
                              value="Zážitkový balík"
                              id="zazitok"
                            />
                            <label className="label-type" htmlFor="zazitok">
                              Zážitkový
                            </label>
                          </div>
                          <div className="form-control">
                            <input
                              {...register("balik", { required: true })}
                              type="radio"
                              value="Bašavel balík"
                              id="basavel"
                            />
                            <label className="label-type" htmlFor="basavel">
                              Bašavel
                            </label>
                          </div>
                          <div className="form-control">
                            <input
                              {...register("balik", { required: true })}
                              type="radio"
                              value="Vlastné riešenie"
                              id="custom"
                              // onClick={() => {setCustomEffects(true)}}
                            />
                            <label className="label-type" htmlFor="custom">
                              Vlastné riešenie
                            </label>
                          </div>
                        </div>

												{customEffect === "Romantický balík" && (
														<div className="wrap-package">
															<ul>
																<li>
																	1x Plazivý dym
																</li>
																<li>
																	2x Studené iskry
																</li>
															</ul>
														</div>
													)}

													{customEffect === "Zážitkový balík" && (
														<div className="wrap-package">
															<ul>
																<li>
																	1x Plazivý dym
																</li>
																<li>
																	2x Studené iskry
																</li>
																<li>
																	1x Konfety
																</li>
															</ul>
														</div>
													)}

													{customEffect === "Bašavel balík" && (
														<div className="wrap-package">
															<ul>
																<li>
																	1x Plazivý dym
																</li>
																<li>
																	2x Studené iskry
																</li>
																<li>
																	1x Konfety
																</li>
																<li>
																	1x Co2 GUN
																</li>
																<li>
																	1x Co2 JET
																</li>
															</ul>
														</div>
													)}

                        {errors.balik && errors.balik.type === "required" && (
                          <Alert
                            severity="error"
                            className="error-form-message"
                          >
                            {messageRequiredProduct}
                          </Alert>
                        )}
                      </div>
                    </div>

                    <div className="custom-solution box-radio">
                      <label>Výber Efektov - vlastné riešenie</label>
                      <div className="wrap-radio">
                        {customEffect === "Vlastné riešenie" && (
                          <>
                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Plazivý dym"
                                id="plazivy"
                              />
                              <label className="label-type" htmlFor="plazivy">
                                Plazivý dym
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="CO2 Gun"
                                id="cogun"
                              />
                              <label className="label-type" htmlFor="cogun">
                                CO2 Gun
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Statické osvetlenie"
                                id="static"
                              />
                              <label className="label-type" htmlFor="static">
                                Statické osvetlenie
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Studené iskry"
                                id="studeneiskry"
                              />
                              <label
                                className="label-type"
                                htmlFor="studeneiskry"
                              >
                                Studené iskry
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="CO2 Jet"
                                id="cojet"
                              />
                              <label className="label-type" htmlFor="cojet">
                                CO2 Jet
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Dynamické osvetlenie"
                                id="dynamic"
                              />
                              <label className="label-type" htmlFor="dynamic">
                                Dynamické osvetlenie
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Konfety"
                                id="konfety"
                              />
                              <label className="label-type" htmlFor="konfety">
                                Konfety
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="G-flame"
                                id="glame"
                              />
                              <label className="label-type" htmlFor="glame">
                                G-flame
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Laser show"
                                id="laser"
                              />
                              <label className="label-type" htmlFor="laser">
                                Laser show
                              </label>
                            </div>

                            <div className="form-control">
                              <input
                                {...register("custom", { required: true })}
                                type="checkbox"
                                value="Fotokútik"
                                id="fotokutik"
                              />
                              <label className="label-type" htmlFor="fotokutik">
                                Fotokútik
                              </label>
                            </div>
                          </>
                        )}
                      </div>

                      {errors.custom && errors.custom.type === "required" && (
                        <Alert severity="error" className="error-form-message">
                          {messageRequiredCustom}
                        </Alert>
                      )}
                    </div>
                  </section>

                  <section className="wrap-textarea">
                    <label className="label-input">Správa pre nás</label>
                    <textarea
                      id="sprava"
                      {...register("sprava", { required: true })}
                      placeholder="Napíšte nám podrobnosti o udalosti."
                    />
                    {errors.sprava && errors.sprava.type === "required" && (
                      <Alert severity="error" className="error-form-message">
                        {messageRequiredMessage}
                      </Alert>
                    )}
                  </section>

                  <section className="wrap-gdpr">
                    <div className="form-control">
                      <input
                        {...register("gdpr", { required: true })}
                        type="checkbox"
                        id="gdpr"
                        value="Suhlasím"
                      />
                      <label className="label-type" htmlFor="gdpr">
                        Súhlasím so spracovaním osobných údajov v súlade s
                        nariadením GDPR o ochrane osobných údajov za účelom
                        komunikácie.
                      </label>
                    </div>
                    {errors.gdpr && errors.gdpr.type === "required" && (
                      <Alert severity="error" className="error-form-message">
                        {messageRequiredGDPR}
                      </Alert>
                    )}
                  </section>
                  {isSuccessfullySubmitted && (
                    <Stack sx={{ width: "100%", mb: 1 }} spacing={2}>
                      <Alert severity="success">{SuccessfullySubmitted}</Alert>
                    </Stack>
                  )}

                  {isErrorSubmitted && (
                    <Stack sx={{ width: "100%", mb: 1 }} spacing={2}>
                      <Alert severity="error">{ErrorSubmitted}</Alert>
                    </Stack>
                  )}
                  <section className="wrap-submit">
                    <button className="btn-submit" type="submit" id="submit" aria-label="Send form">
                      Odoslať
                    </button>
                  </section>
                </form>
              </div>
            </div>
          </section>

					<section>
            <div className="wrap-contact wrap-content">
              <h6>
                Ak chcete svoje podujatie vizuálne vylepšiť kontaktujte nás, aby
                sme prediskutovali vaše požiadavky.
              </h6>

              <div className="group">
                <div className="contact">
                  <MailOutlineIcon fontSize="large" />
                  <a href="mailto:info@specialneefekty.sk">
                    info@specialneefekty.sk
                  </a>
                </div>

                <div className="contact">
                  <InstagramIcon fontSize="large" />
                  <a
                    href="https://www.instagram.com/specialne_efekty/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </div>

                <div className="contact">
                  <PhoneAndroidIcon fontSize="large" />
                  <a href="tel:+421948880136">+421 948 880 136</a>
                </div>

                {/* <div className="contact">
                  <YouTubeIcon fontSize="large" />
                  <a
                    href="https://www.youtube.com/channel/UCV6_OhCg-OluhvvMgFPxKNQ/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Youtube
                  </a>
                </div> */}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Kontakt;
