import React, { useState } from "react";
import data from "../../data/ponuka/data.json";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/virtual";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Helmet } from 'react-helmet';



const Iskry = () => {
  const [effects] = useState(data.effects);
  const [value, setValue] = useState(1);

  const {
    title,
    description,
    bubbles,
    imageSlider,
  } = effects[value];

  return (
    <>
      <div className="container iskry">
				<Helmet>
        	<link rel="canonical" href="https://www.specialneefekty.sk/ponuka/iskry" />
     		</Helmet>
        <section>
          <div className="wrap-content">
            <h2>{title}</h2>
            <div className="video-title">
							<div className="effect-img effect-spark">
						</div>
            </div>

            <div>
              <article>
                <p className="content">{description}</p>

                <div className="bubbles-wrap">
                  <div className="bubbles-check">
                    <TaskAltIcon />
                    <p>{bubbles.one}</p>
                  </div>
                  <div className="bubbles-check">
                    <TaskAltIcon />
                    <p>{bubbles.two}</p>
                  </div>
                  <div className="bubbles-check">
                    <TaskAltIcon />
                    <p>{bubbles.three}</p>
                  </div>
                </div>

                <aside className="aside-direction">
                  <p></p>
                </aside>
              </article>
              <div className="content-arrow">
                <KeyboardDoubleArrowDownIcon />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="wrap-content">
            <Swiper
              breakpoints={{
                315: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 1.2,
                },
                760: {
                  slidesPerView: 2,
                },
                1030: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation, Scrollbar, A11y, Virtual]}
              spaceBetween={50}
              slidesPerView={3}
              navigation
              scrollbar={{ draggable: true }}
              virtual
              lazy={true}
            >
              {imageSlider.map((imgSlide, index) => (
                <SwiperSlide key={index} virtualIndex={index}>
                  <img
                    src={imgSlide.thumbnail}
                    data-src={imgSlide.thumbnail}
                    alt="iskry"
                    className="swiper-lazy"
										loading="lazy"
                  />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </div>
    </>
  );
};

export default Iskry;
