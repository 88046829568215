import { useState } from "react";
import aboutPlaceImg_1 from "../assets/images/about/art-hotel.webp";
import aboutPlaceImg_2 from "../assets/images/about/pod-gastankou.webp";
import aboutPlaceImg_3 from "../assets/images/about/ruban.webp";
import source from "../assets/icons/icon-source.svg";
import data from "../data/about/data.json";
import { Link, NavLink } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/virtual";
import { Helmet } from 'react-helmet';

const Onas = () => {
  const [about] = useState(data.about);
  const [value, setValue] = useState(0);

  const { name, fullname, images, description, link, who } = about[value];

  return (
    <>
      <div>
				<Helmet>
        	<link rel="canonical" href="https://www.specialneefekty.sk/o-nas" />
     		 </Helmet>
        <main className="container about-page">
					<section className="section-team">
            <div className="border"></div>
            <h4>Zoznámte sa s naším tímom</h4>
            <div className="wrap-content">
              <div className="wrap-team">
                <div className="team-img">
                  <img src={images.png} title={name} alt={name} width="600px" loading="lazy"/>
                </div>
                <div className="team-group">
                  <ul>
                    {about.map((people, index) => (
                      <li key={index}>
                        <NavLink
                          to=""
                          onClick={() => setValue(index)}
                          className={`${index === value && "border-active"}`}
                        >
                          {people.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>

                  <h3>{name}</h3>
                  <h6>{fullname}</h6>
                  <p className="description">{description}</p>
                  <div className="source">
                    <img src={source} alt="source" />
                    <a
                      className="link"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                  </div>
                  <p className="title-about">Kto sme?</p>
                  <p className="text-about">{who}</p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="border"></div>
            <h4>Čo sú špeciálne efekty?</h4>

            <div className="wrap-service wrap-content">
              <h6>Vznik brandu</h6>
              <p className="content">
              	Na základe našich mnohoročných skúseností so svadbami a inými podujatiami sme vytvorili značku Špeciálne efekty, ktorá vám ponúka širokú škálu služieb pre vaše podujatie. Bez ohľadu na to, či sa jedná o svadbu, oslavu, firemnú akciu, konferenciu alebo koncert, naši skúsení členovia sú tu pre vás, aby vám pomohli vytvoriť nezabudnuteľné zážitky pre vás a vašich hostí.
              </p>
            </div>

            <div className="wrap-service wrap-content">
              <h6>Naša filozofia</h6>
              <p className="content">
                Radi spolupracujeme s našimi klientmi, aby sme vytvorili jedinečnú atmosféru pre ich živé podujatie. S našimi efektami a skúsenosťami pomáhame vytvárať nezabudnuteľné zážitky, ktoré prenesú hostí do iného sveta a zanechajú ich ohromených. Naším cieľom je prekonať očakávania a vytvoriť podujatia, ktoré sa budú spomínať ešte dlho po ich skončení.
              </p>
            </div>

            <div className="wrap-service wrap-content">
              <h6>Kde pôsobíme</h6>
              <p className="content">
                Poskytujeme profesionálne a spoľahlivé služby špeciálnych efektov pre podujatia po celom Slovensku. Naši skúsení členovia sú pripravený pomôcť vytvoriť nezabudnuteľnú atmosféru pre vaše podujatie - či už ide o svadbu, oslavu, firemnú akciu, konferenciu alebo koncert. Kontaktujte nás a spoločne vytvoríme podujatie, ktoré prekročí vaše očakávania a zostane v pamäti Vám aj Vašim hosťom.
              </p>
              <section>
                <Swiper
                  breakpoints={{
                    315: {
                      slidesPerView: 1,
                    },
                    600: {
                      slidesPerView: 1.2,
                    },
                    760: {
                      slidesPerView: 2,
                    },
                    1030: {
                      slidesPerView: 3,
                    },
                  }}
                  modules={[Navigation, Scrollbar, A11y, Virtual]}
                  spaceBetween={20}
                  slidesPerView={3}
                  navigation
                  scrollbar={{ draggable: true }}
                  virtual
                  lazy={true}
                >
                  <SwiperSlide>
                    <img
                      src={aboutPlaceImg_1}
                      data-src={aboutPlaceImg_1}
                      alt="image"
                      className="swiper-lazy"
											loading="lazy"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={aboutPlaceImg_2}
                      data-src={aboutPlaceImg_2}
                      alt="image"
                      className="swiper-lazy"
											loading="lazy"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src={aboutPlaceImg_3}
                      data-src={aboutPlaceImg_3}
                      alt="image"
                      className="swiper-lazy"
											loading="lazy"
                    />
                  </SwiperSlide>
                </Swiper>
              </section>
            </div>
          </section>
        </main>
				<aside className="aside-direction">
          <p>
            Ak chcete svoje podujatie vizuálne vylepšiť, inšpiráciu a nápady
            nájdete na našom <a href="https://www.instagram.com/specialne_efekty/" rel="noreferrer" target="_blank">instagrame</a>
          </p>
          <Link to="https://www.instagram.com/specialne_efekty/" className="btn-primary" target="_blank">
            Realizácie
          </Link>
        </aside>
      </div>
    </>
  );
};

export default Onas;
