import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";


const Header = () => {
	const menuRef = useRef(null);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

	const handleCloseMenu = () => {
    setNavbarOpen(false);
  };

	const handleClickOutside = (event) => {
    if (navbarOpen && !menuRef.current.contains(event.target)) {
      handleCloseMenu();
    }
  };

	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navbarOpen]);

	useEffect(() => {
  const main = document.querySelector('main');
	const logo = document.querySelector('.logo');

  if (navbarOpen) {
    main.classList.add('openNav-blurred');
		logo.classList.add('openNav-blurred');
  } else {
    main.classList.remove('openNav-blurred');
		logo.classList.remove('openNav-blurred');
  }
}, [navbarOpen]);

  return (
    <>
      <header>
				<div className="header-wrap">
					<a href="/"><h1 className="logo">Špeciálne efekty</h1></a>

					<nav>
						<ul
							className={navbarOpen ? "navOpen" : ""}  ref={menuRef}
						>
							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active-nav" : "")}
									to="/"
									onClick={handleCloseMenu}
								>
									Domov
								</NavLink>
							</li>
							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active-nav" : "")}
									to="/o-nas"
									onClick={handleCloseMenu}
								>
									O nás
								</NavLink>
							</li>
							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active-nav" : "")}
									to="/ponuka/plazivy-dym"
									onClick={handleCloseMenu}
								>
									Plazivý dym
								</NavLink>
							</li>
							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active-nav" : "")}
									to="/ponuka/iskry"
									onClick={handleCloseMenu}
								>
									Iskry
								</NavLink>
							</li>
							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active-nav" : "")}
									to="/ponuka/co2"
									onClick={handleCloseMenu}
								>
									CO2
								</NavLink>
							</li>

							<li>
								<NavLink
									className={({ isActive }) => (isActive ? "active-nav" : "")}
									to="/kontakt"
									onClick={handleCloseMenu}
								>
									Kontakt
								</NavLink>
							</li>
						</ul>
					</nav>

					<button className="menu-icon" onClick={handleToggle} aria-label="menu">
						<div>
							{navbarOpen ? (
								<ClearIcon style={{ width: "2rem", height: "auto" }} />
							) : (
								<MenuIcon style={{ width: "2rem", height: "auto" }} />
							)}
						</div>
					</button>
				</div>
      </header>
    </>
  );
};

export default Header;
