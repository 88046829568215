import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PrivacyPolice = () => {
	return (
		<main className="container wrap-content privacy-policy-page">
			<div>
				<Helmet>
					<link
						rel="canonical"
						href="https://www.specialneefekty.sk/privacy-policy"
					/>
				</Helmet>

				<h1 className="policy">Zásady ochrany osobných údajov</h1>
				<p>Posledná aktualizácia: apríl 2023</p>
				<p>
					Tieto zásady ochrany osobných údajov opisujú naše zásady a postupy
					týkajúce sa zhromažďovania, používania a zverejňovania vašich
					informácií pri používaní Služby a informujú vás o vašich právach na
					ochranu osobných údajov a o tom, ako vás chráni zákon.
				</p>
				<p>
					Vaše osobné údaje používame na poskytovanie a zlepšovanie Služby.
					Používaním Služby súhlasíte so zhromažďovaním a používaním informácií
					v súlade s týmito Zásadami ochrany osobných údajov. Tieto Zásady
					ochrany osobných údajov boli vytvorené pomocou
					<Link
						to="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
						target="_blank"
					>
						bezplatného generátora zásad ochrany osobných údajov.
					</Link>
					.
				</p>
				<h3>Prevádzkovateľ</h3>
				<ul>
					<li>
						<strong>Obchodné meno:</strong> Bc. Patrik Čižmár
					</li>
					<li>
						<strong>IČO:</strong> 53660331
					</li>
					<li>
						<strong>Miesto podnikania:</strong> 01701 Sverepec 436
					</li>
					<li>
						<strong>Telefónny kontakt:</strong> +421 948 880 136
					</li>
				</ul>

				<h1>Výklad a definície</h1>
				<h2>Výklad</h2>
				<p>
					Slová, ktorých začiatočné písmeno je veľké, majú význam definovaný za
					týchto podmienok. Nasledujúce definície majú rovnaký význam bez ohľadu
					na to, či sú uvedené v jednotnom alebo množnom čísle.
				</p>
				<h2>Definície</h2>
				<p>Na účely týchto zásad ochrany osobných údajov:</p>
				<ul>
					<li>
						<p>
							<strong>Konto</strong> znamená jedinečné konto vytvorené pre vás
							na prístup k našej službe alebo jej častiam.
						</p>
					</li>
					<li>
						<p>
							<strong>Pridružený subjekt (Affiliate)</strong> znamená subjekt,
							ktorý kontroluje, je kontrolovaný alebo je pod spoločnou kontrolou
							so stranou, pričom "kontrola" znamená vlastníctvo 50 % alebo viac
							akcií, podielov na základnom imaní alebo iných cenných papierov
							oprávnených hlasovať pri voľbe riaditeľov alebo iných riadiacich
							orgánov.
						</p>
					</li>
					<li>
						<p>
							<strong>Spoločnosť</strong> (v tejto zmluve označovaná ako &quot;
							Spoločnosť &quot;, &quot;My&quot;, &quot;Nás&quot; alebo
							&quot;Naša&quot; ) sa vzťahuje na Bc. Patrik Čižmár, 01701
							Sverepec 436.
						</p>
					</li>
					<li>
						<p>
							<strong>Súbory cookie</strong> cookie sú malé súbory, ktoré webová
							lokalita umiestňuje do vášho počítača, mobilného zariadenia alebo
							akéhokoľvek iného zariadenia a ktoré okrem iného obsahujú údaje o
							histórii vášho prehliadania na danej webovej lokalite.
						</p>
					</li>
					<li>
						<p>
							<strong>Krajina</strong> sa týka: Slovakia
						</p>
					</li>
					<li>
						<p>
							<strong>Zariadenie</strong> znamená akékoľvek zariadenie, ktoré má
							prístup k Službe, napríklad počítač, mobilný telefón alebo
							digitálny tablet.
						</p>
					</li>
					<li>
						<p>
							<strong>Osobné údaje</strong> sú akékoľvek informácie, ktoré sa
							týkajú identifikovanej alebo identifikovateľnej osoby.
						</p>
					</li>
					<li>
						<p>
							<strong>Služba</strong> sa vzťahuje na webové sídlo.
						</p>
					</li>
					<li>
						<p>
							<strong>Poskytovateľ služieb</strong> znamená akúkoľvek fyzickú
							alebo právnickú osobu, ktorá spracúva údaje v mene spoločnosti.
							Ide o spoločnosti alebo fyzické osoby tretích strán, ktoré
							Spoločnosť zamestnáva na uľahčenie poskytovania Služby, na
							poskytovanie Služby v mene Spoločnosti, na vykonávanie služieb
							súvisiacich so Službou alebo na pomoc Spoločnosti pri analýze
							spôsobu používania Služby.
						</p>
					</li>
					<li>
						<p>
							<strong>Údaje o používaní</strong> sa vzťahujú na automaticky
							zhromažďované údaje, ktoré sú generované buď používaním služby,
							alebo samotnou infraštruktúrou služby (napríklad trvanie návštevy
							stránky).
						</p>
					</li>
					<li>
						<p>
							<strong>Webová stránka</strong> odkazuje na Specialneefekty,
							dostupné z
							<Link
								to="https://www.specialneefekty.sk/"
								rel="external nofollow noopener"
								target="_blank"
							>
								https://www.specialneefekty.sk/
							</Link>
						</p>
					</li>
					<li>
						<p>
							<strong>Vy</strong> znamená fyzickú osobu, ktorá pristupuje k
							Službe alebo ju používa, prípadne spoločnosť alebo inú právnickú
							osobu, v mene ktorej táto osoba pristupuje k Službe alebo ju
							používa.
						</p>
					</li>
				</ul>
				<h1>Zhromažďovanie a používanie vašich osobných údajov</h1>
				<h2>Typy zozbieraných údajov:</h2>
				<h3>Osobné údaje</h3>
				<p>
					Pri používaní našej služby vás môžeme požiadať, aby ste nám poskytli
					určité osobné údaje, ktoré možno použiť na kontaktovanie alebo
					identifikáciu. Osobne identifikovateľné informácie môžu okrem iného
					zahŕňať:
				</p>
				<ul>
					<li>
						<p>E-mailová adresa</p>
					</li>
					<li>
						<p>Meno a priezvisko</p>
					</li>
					<li>
						<p>Telefónne číslo</p>
					</li>
					<li>
						<p>Adresa, Štát, Kraj, PSČ, Mesto</p>
					</li>
					<li>
						<p>Údaje o používaní</p>
					</li>
				</ul>
				<h3>Údaje o používaní</h3>
				<p>
					Údaje o používaní sa zhromažďujú automaticky pri používaní Služby.
				</p>
				<p>
					Údaje o používaní môžu zahŕňať informácie, ako je adresa internetového
					protokolu vášho zariadenia (napr. IP adresa), typ prehliadača, verzia
					prehliadača, stránky našej služby, ktoré ste navštívili, čas a dátum
					vašej návštevy, čas strávený na týchto stránkach, jedinečné
					identifikátory zariadenia a iné diagnostické údaje.
				</p>
				<p>
					Keď pristupujete k Službe prostredníctvom mobilného zariadenia alebo
					cez mobilné zariadenie, môžeme automaticky zhromažďovať určité
					informácie, okrem iného typ mobilného zariadenia, ktoré používate,
					jedinečné ID vášho mobilného zariadenia, IP adresu vášho mobilného
					zariadenia, váš mobilný operačný systém, typ mobilného internetového
					prehliadača, ktorý používate, jedinečné identifikátory zariadenia a
					iné diagnostické údaje.
				</p>
				<p>
					Môžeme tiež zhromažďovať informácie, ktoré váš prehliadač odošle vždy,
					keď navštívite našu službu alebo keď k službe pristupujete
					prostredníctvom mobilného zariadenia.
				</p>
				<h3>Sledovacie technológie a súbory cookie</h3>
				<p>
					Používame súbory cookie a podobné technológie sledovania na sledovanie
					aktivity na našej službe a ukladanie určitých informácií. Na
					zhromažďovanie a sledovanie informácií a na zlepšovanie a analýzu
					Našej služby sa používajú sledovacie technológie, ako sú majáky,
					značky a skripty. Technológie, ktoré používame, môžu zahŕňať:
				</p>
				<ul className="zoznam">
					<li>
						<strong>Súbory cookie alebo súbory cookie prehliadača.</strong> .
						Súbor cookie je malý súbor umiestnený vo vašom zariadení. Vášmu
						prehliadaču môžete dať pokyn, aby odmietol všetky súbory cookie
						alebo aby informoval o odoslaní súboru cookie. Ak však Súbory cookie
						neprijmete, možno nebudete môcť používať niektoré časti našej
						Služby. Pokiaľ ste neupravili nastavenie Vášho prehliadača tak, aby
						odmietal súbory cookie, naša Služba môže používať súbory cookie.
					</li>
					<li>
						<strong>Webové majáky.</strong> Niektoré časti našej služby a naše
						e-maily môžu obsahovať malé elektronické súbory známe ako webové
						signály (označované aj ako jasné gify, pixelové značky a
						jednopixelové gify), ktoré Spoločnosti umožňujú napríklad počítať
						používateľov, ktorí navštívili tieto stránky alebo otvorili e-mail,
						a ďalšie súvisiace štatistiky webových stránok (napríklad
						zaznamenávanie obľúbenosti určitej časti a overovanie integrity
						systému a servera).
					</li>
				</ul>
				<p>
					Súbory cookie môžu byť &quot;trvalé&quot; alebo &quot;session&quot;.
					Trvalé súbory cookie zostávajú vo Vašom osobnom počítači alebo
					mobilnom zariadení, aj keď ste offline, zatiaľ čo súbory cookie
					session sa vymažú hneď, ako zavriete svoj webový prehliadač. Viac
					informácií o súboroch cookie nájdete v článku na
					<Link
						to="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
						target="_blank"
					>
						webovej stránke Bezplatné zásady ochrany osobných údajov.
					</Link>
				</p>
				<p>
					Na účely uvedené nižšie používame reláciu aj trvalé súbory cookie:
				</p>
				<ul>
					<li>
						<p>
							<strong>Nevyhnutné / základné súbory cookie</strong>
						</p>
						<p>Typ: Súbory cookie session</p>
						<p>Spravované nami</p>
						<p>
							Účel: Tieto súbory cookie sú nevyhnutné na poskytovanie služieb
							dostupných prostredníctvom webovej lokality a na to, aby ste mohli
							používať niektoré jej funkcie. Pomáhajú overovať používateľov a
							predchádzať podvodnému používaniu používateľských účtov. Bez
							týchto súborov cookie nie je možné poskytovať služby, o ktoré ste
							požiadali, a tieto súbory cookie používame len na to, aby sme vám
							tieto služby mohli poskytovať.
						</p>
					</li>
					<li>
						<p>
							<strong>
								Zásady používania súborov cookie / Oznámenie Prijímanie súborov
								cookie
							</strong>
						</p>
						<p>Typ: Trvalé súbory cookie</p>
						<p>Spravované nami</p>
						<p>
							Účel: Tieto súbory cookie identifikujú, či používatelia súhlasili
							s používaním súborov cookie na webovej lokalite.
						</p>
					</li>
					<li>
						<p>
							<strong>Funkčnosť Súbory cookie</strong>
						</p>
						<p>Typ: Trvalé súbory cookie</p>
						<p>Spravované nami</p>
						<p>
							Účel: Tieto súbory cookie nám umožňujú zapamätať si vaše voľby pri
							používaní webovej lokality, napríklad zapamätať si vaše
							prihlasovacie údaje alebo jazykové preferencie. Účelom týchto
							súborov cookie je poskytnúť vám osobnejšie skúsenosti a zabrániť
							tomu, aby ste museli pri každom použití webovej lokality znovu
							zadávať svoje preferencie.
						</p>
					</li>
				</ul>
				<p>
					Viac informácií o súboroch cookie, ktoré používame, a o vašich
					možnostiach týkajúcich sa súborov cookie nájdete v našich Zásadách
					používania súborov cookie alebo v časti Súbory cookie v našich
					Zásadách ochrany osobných údajov.
				</p>
				<h2>Používanie vašich osobných údajov</h2>
				<p>Spoločnosť môže používať osobné údaje na tieto účely:</p>
				<ul>
					<li>
						<p>
							<strong>Poskytovanie a údržba našej služby</strong>, vrátane
							monitorovania používania našej služby.
						</p>
					</li>
					<li>
						<p>
							<strong>Správa vášho účtu:</strong> správa vašej registrácie ako
							používateľa služby. Poskytnuté osobné údaje Vám môžu umožniť
							prístup k rôznym funkciám Služby, ktoré sú Vám ako registrovanému
							používateľovi k dispozícii.
						</p>
					</li>
					<li>
						<p>
							<strong>Na plnenie zmluvy:</strong> : vypracovanie, dodržiavanie a
							plnenie kúpnej zmluvy na produkty, položky alebo služby, ktoré ste
							si zakúpili, alebo akejkoľvek inej zmluvy s nami prostredníctvom
							služby.
						</p>
					</li>
					<li>
						<p>
							<strong>Kontaktovať vás:</strong> Kontaktovať Vás prostredníctvom
							e-mailu, telefonických hovorov, SMS alebo iných rovnocenných
							foriem elektronickej komunikácie, ako sú push notifikácie mobilnej
							aplikácie, týkajúce sa aktualizácií alebo informačných oznámení
							súvisiacich s funkciami, produktmi alebo zmluvnými službami
							vrátane bezpečnostných aktualizácií, ak je to potrebné alebo
							primerané na ich realizáciu.
						</p>
					</li>
					<li>
						<p>
							<strong>Poskytovať vám</strong> novinky, špeciálne ponuky a
							všeobecné informácie o inom tovare, službách a podujatiach, ktoré
							ponúkame a ktoré sú podobné tým, ktoré ste si už zakúpili alebo o
							ktoré ste sa zaujímali, pokiaľ ste sa nerozhodli takéto informácie
							nedostávať.
						</p>
					</li>
					<li>
						<p>
							<strong>Spravovať Vaše žiadosti:</strong> Aby sme mohli vybavovať
							a spravovať Vaše žiadosti adresované Nám.
						</p>
					</li>
					<li>
						<p>
							<strong>Pre obchodné transfery:</strong> Môžeme použiť vaše údaje
							na vyhodnotenie alebo uskutočnenie fúzie, odpredaja,
							reštrukturalizácie, reorganizácie, zrušenia alebo iného predaja
							alebo prevodu niektorých alebo všetkých našich aktív, či už ako
							pokračujúceho podniku alebo v rámci konkurzu, likvidácie alebo
							podobného konania, v ktorom sú medzi prevedenými aktívami aj nami
							uchovávané osobné údaje o používateľoch našich služieb.
						</p>
					</li>
					<li>
						<p>
							<strong>Na iné účely</strong>: Vaše údaje môžeme použiť na iné
							účely, napríklad na analýzu údajov, identifikáciu trendov
							používania, určenie účinnosti našich propagačných kampaní a na
							vyhodnotenie a zlepšenie našej služby, produktov, služieb,
							marketingu a vašich skúseností.
						</p>
					</li>
				</ul>
				<p>Vaše osobné údaje môžeme zdieľať v nasledujúcich situáciách:</p>
				<ul className="zoznam">
					<li>
						<strong>S poskytovateľmi služieb:</strong> Vaše osobné údaje môžeme
						zdieľať s poskytovateľmi služieb, aby sme mohli monitorovať a
						analyzovať používanie našej služby, kontaktovať Vás.
					</li>
					<li>
						<strong>Pre obchodné transfery:</strong> Vaše osobné údaje môžeme
						zdieľať alebo prenášať v súvislosti s akoukoľvek fúziou, predajom
						majetku spoločnosti, financovaním alebo akvizíciou celého nášho
						podniku alebo jeho časti inej spoločnosti alebo počas rokovaní o
						takejto akvizícii.
					</li>
					<li>
						<strong>S pridruženými spoločnosťami:</strong> V takom prípade
						budeme od týchto pobočiek vyžadovať, aby dodržiavali tieto zásady
						ochrany osobných údajov. Pridružené spoločnosti zahŕňajú Našu
						materskú spoločnosť a akékoľvek iné dcérske spoločnosti, partnerov v
						spoločnom podniku alebo iné spoločnosti, ktoré kontrolujeme alebo
						ktoré sú pod našou spoločnou kontrolou.
					</li>
					<li>
						<strong>S obchodnými partnermi:</strong> Vaše údaje môžeme zdieľať s
						našimi obchodnými partnermi, aby sme vám mohli ponúknuť určité
						produkty, služby alebo propagačné akcie.
					</li>
					<li>
						<strong>S ostatnými používateľmi:</strong> keď zdieľate osobné
						informácie alebo inak komunikujete vo verejných oblastiach s inými
						používateľmi, tieto informácie môžu vidieť všetci používatelia a
						môžu byť verejne šírené mimo nich.
					</li>
					<li>
						<strong>S vaším súhlasom: </strong> Vaše osobné údaje môžeme
						zverejniť na akýkoľvek iný účel s Vaším súhlasom.
					</li>
				</ul>
				<h2>Uchovávanie vašich osobných údajov</h2>
				<p>
					Spoločnosť bude uchovávať vaše osobné údaje len tak dlho, ako je to
					potrebné na účely uvedené v týchto Zásadách ochrany osobných údajov.
					Vaše Osobné údaje budeme uchovávať a používať v rozsahu potrebnom na
					splnenie našich zákonných povinností (napríklad ak sme povinní
					uchovávať vaše údaje na splnenie platných zákonov), na riešenie sporov
					a na presadzovanie našich právnych dohôd a zásad.
				</p>
				<p>
					Spoločnosť bude uchovávať údaje o používaní aj na účely internej
					analýzy. Údaje o používaní sa vo všeobecnosti uchovávajú kratšiu dobu,
					s výnimkou prípadov, keď sa tieto údaje používajú na posilnenie
					bezpečnosti alebo zlepšenie funkčnosti Našej služby, alebo ak sme zo
					zákona povinní uchovávať tieto údaje dlhšiu dobu.
				</p>
				<h2>Prenos vašich osobných údajov</h2>
				<p>
					Vaše informácie vrátane osobných údajov sa spracúvajú v prevádzkových
					kanceláriách spoločnosti a na ďalších miestach, kde sa nachádzajú
					strany zapojené do spracovania. To znamená, že tieto informácie môžu
					byť prenesené do počítačov - a uchovávané v počítačoch -
					nachádzajúcich sa mimo Vášho štátu, provincie, krajiny alebo inej
					vládnej jurisdikcie, kde sa zákony o ochrane údajov môžu líšiť od
					zákonov Vašej jurisdikcie.
				</p>
				<p>
					Váš súhlas s týmito zásadami ochrany osobných údajov, po ktorom
					nasleduje odoslanie takýchto informácií, predstavuje váš súhlas s
					týmto prenosom.
				</p>
				<p>
					Spoločnosť podnikne všetky primerané kroky potrebné na zabezpečenie
					bezpečného zaobchádzania s vašimi údajmi v súlade s týmito zásadami
					ochrany osobných údajov a žiadny prenos vašich osobných údajov sa
					neuskutoční do organizácie alebo krajiny, ak nie sú zavedené primerané
					kontroly vrátane zabezpečenia vašich údajov a iných osobných
					informácií.
				</p>
				<h2>Odstránenie vašich osobných údajov</h2>
				<p>
					Máte právo vymazať osobné údaje, ktoré sme o vás zhromaždili, alebo
					nás požiadať o pomoc pri ich vymazaní.
				</p>
				<p>
					Naša služba vám môže poskytnúť možnosť vymazať určité informácie o vás
					v rámci služby.
				</p>
				<p>
					Svoje údaje môžete kedykoľvek aktualizovať, zmeniť alebo vymazať tak,
					že sa prihlásite do svojho konta, ak ho máte, a navštívite časť s
					nastaveniami konta, ktorá vám umožňuje spravovať vaše osobné údaje.
					Môžete nás tiež kontaktovať a požiadať o prístup, opravu alebo
					vymazanie akýchkoľvek osobných údajov, ktoré ste nám poskytli.
				</p>
				<p>
					Upozorňujeme však, že určité informácie môžeme potrebovať uchovávať,
					ak na to máme zákonnú povinnosť alebo zákonný dôvod.
				</p>
				<h2>Zverejnenie vašich osobných údajov</h2>
				<h3>Obchodné transakcie</h3>
				<p>
					Ak sa Spoločnosť zúčastní na fúzii, akvizícii alebo predaji aktív,
					Vaše osobné údaje môžu byť prenesené. Predtým, ako sa Vaše osobné
					údaje prenesú a začnú sa na ne vzťahovať iné zásady ochrany osobných
					údajov, poskytneme o tom oznámenie.
				</p>
				<h3>Orgány činné v trestnom konaní</h3>
				<p>
					Za určitých okolností môže byť spoločnosť povinná zverejniť vaše
					osobné údaje, ak to vyžaduje zákon alebo ak ide o odpoveď na platnú
					žiadosť orgánov verejnej moci (napr. súdu alebo vládneho orgánu).
				</p>
				<h3>Ďalšie právne požiadavky</h3>
				<p>
					Spoločnosť môže zverejniť vaše osobné údaje v dobrej viere, že takéto
					konanie je nevyhnutné na:
				</p>
				<ul className="zoznam">
					<li>Splnenie zákonnej povinnosti</li>
					<li>chrániť a brániť práva alebo majetok spoločnosti</li>
					<li>
						predchádzať alebo vyšetrovať možné protiprávne konanie v súvislosti
						so službou.
					</li>
					<li>
						chrániť osobnú bezpečnosť používateľov služby alebo verejnosti.
					</li>
					<li>Ochrana pred právnou zodpovednosťou</li>
				</ul>
				<h2>Bezpečnosť vašich osobných údajov</h2>
				<p>
					Bezpečnosť vašich osobných údajov je pre nás dôležitá, ale
					nezabúdajte, že žiadny spôsob prenosu cez internet ani spôsob
					elektronického ukladania nie je stopercentne bezpečný. Hoci sa snažíme
					používať komerčne prijateľné prostriedky na ochranu Vašich Osobných
					údajov, nemôžeme zaručiť ich absolútnu bezpečnosť.
				</p>
				<h1>Ochrana súkromia detí</h1>
				<p>
					Naša služba nie je určená osobám mladším ako 13 rokov. Vedome
					nezhromažďujeme osobné údaje od osôb mladších ako 13 rokov. Ak ste
					rodič alebo opatrovník a viete, že nám vaše dieťa poskytlo osobné
					údaje, kontaktujte nás. Ak sa dozvieme, že sme zhromaždili Osobné
					údaje od osoby mladšej ako 13 rokov bez overenia súhlasu rodičov,
					podnikneme kroky na odstránenie týchto informácií z Našich serverov.
				</p>
				<p>
					Ak sa musíme spoliehať na súhlas ako právny základ na spracovanie
					vašich informácií a vaša krajina vyžaduje súhlas rodiča, môžeme pred
					zhromažďovaním a používaním týchto informácií vyžadovať súhlas vášho
					rodiča.
				</p>
				<h1>Odkazy na iné webové stránky</h1>
				<p>
					Naša služba môže obsahovať odkazy na iné webové stránky, ktoré nie sú
					prevádzkované nami. Ak kliknete na odkaz tretej strany, budete
					presmerovaní na stránku tejto tretej strany. Dôrazne Vám odporúčame,
					aby ste si prečítali Zásady ochrany osobných údajov každej navštívenej
					stránky.
				</p>
				<p>
					Nemáme žiadnu kontrolu nad obsahom, zásadami ochrany osobných údajov
					alebo postupmi akýchkoľvek webových lokalít alebo služieb tretích
					strán a nenesieme za ne žiadnu zodpovednosť.
				</p>
				<h1>Zmeny týchto zásad ochrany osobných údajov</h1>
				<p>
					Naše zásady ochrany osobných údajov môžeme z času na čas aktualizovať.
					O všetkých zmenách vás budeme informovať uverejnením nových zásad
					ochrany osobných údajov na tejto stránke.
				</p>
				<p>
					Pred nadobudnutím účinnosti zmeny vás o tom budeme informovať e-mailom
					a/alebo výrazným oznámením na našej službe a aktualizujeme dátum
					"Poslednej aktualizácie" v hornej časti týchto zásad ochrany osobných
					údajov.
				</p>
				<p>
					Odporúčame vám, aby ste pravidelne kontrolovali tieto zásady ochrany
					osobných údajov a zisťovali ich prípadné zmeny. Zmeny týchto Zásad
					ochrany osobných údajov sú účinné po ich zverejnení na tejto stránke.
				</p>
				<h1>Kontaktujte nás</h1>
				<p>
					Ak máte akékoľvek otázky týkajúce sa týchto zásad ochrany osobných
					údajov, môžete nás kontaktovať:
				</p>
				<ul>
					<li>E-mailom: info@specialneefekty.sk</li>
				</ul>
			</div>
		</main>
	);
};

export default PrivacyPolice;
