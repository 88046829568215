import { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';


const Ponuka = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div>
				<Helmet>
					<link rel="canonical" href="https://www.specialneefekty.sk/ponuka" />
				</Helmet>
        <main className="effects-page">
          <section className="effect-nav">

          </section>

          <Outlet />

          <section>
            <div className="wrap-content">
              <aside className="aside-direction">
                <p>S výberom efektov a dizajnom udalosti vám radi pomôžeme</p>
                <Link to="/kontakt" className="btn-primary">
                  Kontaktujte nás
                </Link>
              </aside>

              <aside className="aside-direction wrap-safety">
                <p className="safety">Bezpečnosť je našou prioritou</p>
                <p className="content">
                  Pri používaní špeciálnych efektov dbáme na bezpečnosť a pohodu vašich hostí, aby si mohli plne užívať zážitok počas podujatia.
                </p>
              </aside>
            </div>
          </section>

          <section>
            <div className="wrap-content wrap-instagram">
              <h6>
                Zábery z udalostí nájdete aj na našom
                <a
                  href="https://www.instagram.com/specialne_efekty/"
                  target="_blank"
                  rel="noreferrer"
                >
                  instagrame
                </a>
              </h6>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Ponuka;
