import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import InstaIcon from "../assets/icons/social/instagram-icon.svg";
import FbIcon from "../assets/icons/social/facebook-icon.svg";
import YtbIcon from "../assets/icons/social/youtube-icon.svg";
import bgDesktop1 from "../assets/images/bg/desktop/home-bg.webp";
import bgDesktop2 from "../assets/images/bg/desktop/plaz-dym-wedding-4.webp";
import bgDesktop3 from "../assets/images/bg/desktop/co2-wedding-2.webp";
import bgDesktop4 from "../assets/images/bg/desktop/co2-wedding-1.webp";
import bgDesktop5 from "../assets/images/bg/desktop/iskry-wedding-2.webp";

import bgMobile1 from "../assets/images/bg/mobile/home-bg.webp";
import bgMobile2 from "../assets/images/bg/mobile/plaz-dym-wedding-4.webp";
import bgMobile3 from "../assets/images/bg/mobile/co2-wedding-2.webp";
import bgMobile4 from "../assets/images/bg/mobile/co2-wedding-1.webp";
import bgMobile5 from "../assets/images/bg/mobile/iskry-wedding-2.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { EffectFade, Pagination, Autoplay, Navigation } from "swiper";
import { Helmet } from "react-helmet";

const Domov = () => {
	// const [imageUrl1, setImageUrl1] = useState(bgDesktop1);
	// const [imageUrl2, setImageUrl2] = useState(bgDesktop2);
	// const [imageUrl3, setImageUrl3] = useState(bgDesktop3);
	// const [imageUrl4, setImageUrl4] = useState(bgDesktop4);
	// const [imageUrl5, setImageUrl5] = useState(bgDesktop5);
	// const [isMobile, setIsMobile] = useState(false);

	const location = useLocation();

	const progressCircle = useRef(null);
	const progressContent = useRef(null);
	const onAutoplayTimeLeft = (s, time, progress) => {
		progressCircle.current.style.setProperty("--progress", 1 - progress);
		progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
	};

	useEffect(() => {
		const body = document.getElementsByTagName("footer")[0];
		if (location.pathname === "/") {
			body.classList.add("footer-hidden");
		} else {
			body.classList.remove("footer-hidden");
		}
		return () => {
			body.classList.remove("footer-hidden");
		};
	}, [location]);

	// useEffect(() => {
	//   const handleResize = () => {
	//     if (window.innerWidth <= 575) {
	//       setIsMobile(true);
	//       setImageUrl1(bgMobile1);
	//       setImageUrl2(bgMobile2);
	//       setImageUrl3(bgMobile3);
	//       setImageUrl4(bgMobile4);
	//       setImageUrl5(bgMobile5);
	//     } else {
	//       setIsMobile(false);
	//       setImageUrl1(bgDesktop1);
	//       setImageUrl2(bgDesktop2);
	//       setImageUrl3(bgDesktop3);
	//       setImageUrl4(bgDesktop4);
	//       setImageUrl5(bgDesktop5);
	//     }
	//   };
	//   window.addEventListener('resize', handleResize);
	//   return () => {
	//     window.removeEventListener('resize', handleResize);
	//   };
	// }, [isMobile]);

	return (
		<>
			<div>
				<Helmet>
					<link rel="canonical" href="https://www.specialneefekty.sk" />
				</Helmet>
				<main className="container home-page">
					<div className="wrap-home-bg-slider">
						<Swiper
							style={{
								"--swiper-navigation-color": "#fff",
								"--swiper-pagination-color": "#fff",
							}}
							pagination={{
								// type: "progressbar",
								clickable: true,
							}}
							effect={"fade"}
							loop={true}
							speed={"2000"}
							autoplay={{
								delay: 10000,
								disableOnInteraction: false,
							}}
							navigation={true}
							modules={[EffectFade, Pagination, Autoplay, Navigation]}
							onAutoplayTimeLeft={onAutoplayTimeLeft}
							className="mySwiper"
						>
							<SwiperSlide>
								<picture>
									<source
										media="(min-width: 550px)"
										srcSet={`${bgDesktop4} 1280w`}
									></source>
									<source
										media="(min-width: 320px)"
										srcSet={`${bgMobile4} 550w`}
									></source>
									<img fetchpriority="high" src={bgDesktop4} alt="slider" />
								</picture>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<picture>
									<source
										media="(min-width: 550px)"
										srcSet={`${bgDesktop2} 1280w`}
									></source>
									<source
										media="(min-width: 320px)"
										srcSet={`${bgMobile2} 575w`}
									></source>
									<img
										fetchpriority="low"
										src={bgDesktop2}
										alt="slider"
										loading="lazy"
									/>
								</picture>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<picture>
									<source
										media="(min-width: 550px)"
										srcSet={`${bgDesktop3} 1280w`}
									></source>
									<source
										media="(min-width: 320px)"
										srcSet={`${bgMobile3} 575w`}
									></source>
									<img
										fetchpriority="low"
										src={bgDesktop3}
										alt="slider"
										loading="lazy"
									/>
								</picture>

								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<picture>
									<source
										media="(min-width: 550px)"
										srcSet={`${bgDesktop5} 1280w`}
									></source>
									<source
										media="(min-width: 320px)"
										srcSet={`${bgMobile5} 575w`}
									></source>
									<img
										fetchpriority="low"
										src={bgDesktop5}
										alt="slider"
										loading="lazy"
									/>
								</picture>

								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>
							<SwiperSlide>
								<picture>
									<source
										media="(min-width: 550px)"
										srcSet={`${bgDesktop1} 1280w`}
									></source>
									<source
										media="(min-width: 320px)"
										srcSet={`${bgMobile1} 575w`}
									></source>
									<img
										fetchpriority="low"
										src={bgDesktop1}
										alt="slider"
										loading="lazy"
									/>
								</picture>
								<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
							</SwiperSlide>

							<div className="autoplay-progress" slot="container-end">
								<svg viewBox="0 0 48 48" ref={progressCircle}>
									<circle cx="24" cy="24" r="20"></circle>
								</svg>
								<span ref={progressContent}></span>
							</div>
						</Swiper>
					</div>

					<section className="">
						<div className="wrap-home wrap-content">
							<div className="content-wrap">
								<h4>Prekvapte svojich hostí s nezvyčajnými prvkami</h4>
								<p className="content">
									Ak je vaším zámerom zaujať a inšpirovať publikum alebo hostí,
									posuňte svoju udalosť na vyššiu úroveň pomocou špeciálnych
									efektov, ako sú hmlové systémy, svetlomety, konfety atď. Tieto
									úžasné efekty určite dodajú vašej udalosti pôsobivú atmosféru.
								</p>
								<Link to="/kontakt" className="btn-primary">
									Objednať
									<EastIcon />
								</Link>
							</div>
						</div>
					</section>

					<aside className="aside-home wrap-content">
						<a
							href="https://www.instagram.com/specialne_efekty/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={InstaIcon}
								alt="Instagram icon"
								width="45px"
								height="51px"
							/>
						</a>
						<a
							href="https://www.facebook.com/%C5%A0peci%C3%A1lne-Efekty-112483601465693"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={FbIcon}
								alt="Facebook icon"
								width="45px"
								height="51px"
							/>
						</a>
						{/* <a
              href="https://www.youtube.com/channel/UCV6_OhCg-OluhvvMgFPxKNQ/featured"
              target="_blank"
              rel="noreferrer"
            >
              <img src={YtbIcon} alt="Youtube icon" width="45px" height="51px" />
            </a> */}
					</aside>
				</main>
			</div>
		</>
	);
};

export default Domov;
