import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Domov from "./pages/Domov";
import Onas from "./pages/Onas";
import Ponuka from "./pages/Ponuka";
import PlazivyDym from "./pages/effects/PlazivyDym";
import Iskry from "./pages/effects/Iskry";
import Co2 from "./pages/effects/Co2";
import Kontakt from "./pages/Kontakt";
import PrivacyPolice from "./pages/PrivacyPolice";
import NotFound from "./components/helpers/NotFound";
import Footer from "./components/Footer";

function App() {
	return (
		<>
			<Header />
			<Routes>
				<Route path="/" element={<Domov />} />
				<Route path="o-nas" element={<Onas />} />
				<Route path="ponuka" element={<Ponuka />}>
					<Route path="plazivy-dym" element={<PlazivyDym />} />
					<Route path="iskry" element={<Iskry />} />
					<Route path="co2" element={<Co2 />} />
				</Route>
				<Route path="kontakt" element={<Kontakt />} />
				<Route path="privacy-policy" element={<PrivacyPolice />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</>
	);
}

export default App;
